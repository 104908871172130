import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {checkResponse} from '@/util/common-utils';
import mutations from '@/vuex/modules/profile-new/mutations';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from "ant-design-vue";
import {MESSAGES} from "@/util/message-notification";
import {DE_XUAT_VALUE, DOI_TUONG_VALUE} from "@/util/common-constant";

const state = () => ({
    loading: false,
    api: ConstantAPI.profileNew,
    visible: false,
    action: null,
    profileDetail: null,
    listIdPropose: [],
    listIdObject: [],
    totalUnprocessedProfile: 0,
    isNotHasID: true,
    loadingDetail: false,
    totalPriority: 0,
});

const actions = {
    async propose({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let isSuccess = false;
            if (payload.xinYKien === DE_XUAT_VALUE.XIN_Y_KIEN) {
                const response = await DataService.patch(state.api.PROPOSE.url, payload,
                    null,
                    null,
                    'blob');
                if (response) {
                    isSuccess = true
                }
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `THONG_TIN_CHI_TIET_HO_SO_${Date.now()}.${payload.ids.length > 1 ? 'zip' : 'pdf'}`);
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    notification.success({
                        message: 'Thông báo',
                        description: MESSAGES.MESSAGES_0024,
                        duration: 4,
                    });
                }, 1000)
                commit('setLoading', false)
            } else {
                const response = await DataService.callApi(state.api.PROPOSE, payload);
                checkResponse(response, () => {
                    isSuccess = true;
                });
            }
            commit('setLoading', false)
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async proposeParcel({state, commit}, payload) {
        commit('setLoading', true)
        try {
            let isSuccess = false;
            const isHasXinYKien = payload.filter(item => item.xinYKien === DE_XUAT_VALUE.XIN_Y_KIEN)
            if (isHasXinYKien.length > 0) {
                const response = await DataService.patch(state.api.PROPOSE_PARCEL.url, payload,
                    null,
                    null,
                    'blob');
                if (response) {
                    isSuccess = true
                }
                const url = window.URL.createObjectURL(new Blob([response]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `THONG_TIN_CHI_TIET_HO_SO_${Date.now()}.${isHasXinYKien.length > 1 ? 'zip' : 'pdf'}`);
                document.body.appendChild(link);
                link.click();
                notification.success({
                    message: 'Thông báo',
                    description: MESSAGES.MESSAGES_0024,
                    duration: 4,
                });
            } else {
                const response = await DataService.callApi(state.api.PROPOSE_PARCEL, payload);
                checkResponse(response, () => {
                    isSuccess = true;
                    notification.success({
                        message: 'Thông báo',
                        description: MESSAGES.MESSAGES_0029,
                        duration: 4,
                    });
                });
            }
            setTimeout(() => commit('setLoading', false), 1500)
            return isSuccess;
        } catch (err) {
            console.log(err);
        }
    },
    async getDetail({state, commit}, id) {
        try {
            commit('setLoading', true)
            state.loadingDetail = true
            state.detailEkyc = null
            const response = await DataService.callApi(
                {
                    url: state.api.GET_BY_ID.url + id,
                    method: state.api.GET_BY_ID.method,
                });
            checkResponse(response, async () => {
                state.profileDetail = response.data
                commit('setLoading', false)
                state.loadingDetail = false
            });
            return response.data
        } catch (err) {
            console.log(err);
        }
    },
    async resetData({state}) {
        state.profileDetail = null
        state.isNotHasID = true
    },
    setDataToListPropose({state}, data) {
        state.listIdPropose = data
    },
    setDataToListObject({state}, data) {
        state.listIdObject = data
    },
    async getUnprocessedProfile({state}, payload) {
        try {
            const response = await DataService.callApi(state.api.UNPROCESSED_FILE, payload);
            if (response) {
                state.totalUnprocessedProfile = response.data
            }
        } catch (err) {
            console.log(err);
        }
    },
    setTotalUnprocessedProfile({state}, value) {
        state.totalUnprocessedProfile = value
    },
    setCallDetail({state}, value) {
        state.isNotHasID = value
    },
    resetProfile({state}) {
        state.profileDetail = null
        state.listIdPropose = []
    },
    async handleDuplicateObject({state, commit}, payload) {
        try {
            commit('setLoading', true)
            state.loadingDetail = true
            let isSuccess = false
            const response = await DataService.callApi(state.api.HANDLE_DUPLICATE_OBJECT, {
                id: payload.id,
                xnDoiTuong: payload.listItem
            });
            checkResponse(response, async () => {
                isSuccess = true
                state.loadingDetail = false
            });
            return isSuccess
        } catch (err) {
            console.log(err);
        }
    },
    async handleViolateObject({state, commit}, payload) {
        try {
            commit('setLoading', true)
            state.loadingDetail = true
            let isSuccess = false
            const response = await DataService.callApi(state.api.HANDLE_VIOLATE_OBJECT, {
                id: payload.id,
                xnBBViPham: payload.listItem
            });
            checkResponse(response, async () => {
                isSuccess = true
                state.loadingDetail = false
            });
            return isSuccess
        } catch (err) {
            console.log(err);
        }
    },
    async handleCancellationObject({state, commit}, payload) {
        try {
            commit('setLoading', true)
            state.loadingDetail = true
            let isSuccess = false
            const response = await DataService.callApi(state.api.HANDLE_CANCELLATION_OBJECT, {
                id: payload.id,
                xnGiayToHuy: payload.listItem
            });
            checkResponse(response, async () => {
                isSuccess = true
                state.loadingDetail = false
            });
            return isSuccess
        } catch (err) {
            console.log(err);
        }
    },
    async cancelConfirm({state, commit}, payload) {
        try {
            commit('setLoading', true)
            state.loadingDetail = true
            let isSuccess = false
            let api
            switch (payload.type) {
                case DOI_TUONG_VALUE.DOI_TUONG:
                    api = state.api.CANCEL_DUPLICATE_OBJECT
                    break
                case DOI_TUONG_VALUE.GIAY_TO_HUY:
                    api = state.api.CANCEL_CANCELLATION_OBJECT
                    break
                case DOI_TUONG_VALUE.VI_PHAM:
                    api = state.api.CANCEL_VIOLATE_OBJECT
                    break
            }
            const response = await DataService.callApi(api, {
                id: payload.id,
            });
            checkResponse(response, async () => {
                isSuccess = true
                state.loadingDetail = false
            });
            return isSuccess
        } catch (err) {
            console.log(err);
        }
    },
    async updateChuaXc({state, commit}, id) {
        try {
            commit('setLoading', true)
            const response = await DataService.callApi(
                {
                    url: state.api.GET_BY_ID.url + id,
                    method: state.api.GET_BY_ID.method,
                });
            checkResponse(response,() => {
                state.profileDetail.chuaXc = response.data.chuaXc
                notification.success({
                    message: 'Thông báo',
                    description: 'Đã cập nhật thông tin mới nhất',
                    duration: 4,
                });
            });
        } catch (err) {
            console.log(err);
        } finally {
            commit('setLoading', false)
        }
    },
    async getTotalProfilePriority({state}) {
        try {
            const response = await DataService.callApi(state.api.TOTAL_PRIORITY);
            if (response) {
                state.totalPriority = response.data
            }
        } catch (err) {
            console.log(err);
        }
    },
    setTotalPriority({state}, value) {
        state.totalPriority = value
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
