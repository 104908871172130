export const MESSAGES = {
  MESSAGE_001: 'Duyệt thành công',
  MESSAGE_002: 'Duyệt thất bại',
  MESSAGE_003: 'Xóa thành công',
  MESSAGE_004: 'Xóa thất bại',
  MESSAGE_005: 'Tạo thành công',
  MESSAGE_006: 'Tạo thất bại',
  MESSAGE_007: 'Sửa thành công',
  MESSAGE_008: 'Sửa thất bại',
  MESSAGES_009: 'Ẩn thành công',
  MESSAGES_0010: 'Ẩn thất bại',
  MESSAGES_0011: 'Hiện thị thất bại',
  MESSAGES_0012: 'Hiện thị thành công',
  MESSAGES_0013: 'Bạn có muốn hiển thị không ?',
  MESSAGES_0014: 'Bạn có muốn ẩn không ?',
  MESSAGES_0015: 'Bạn có chắc chắn muốn lưu không?',
  MESSAGES_0016: 'Không có bản ghi được chọn',
  MESSAGES_0017: 'Bạn có chắc chắn muốn xóa không?',
  MESSAGES_0018: 'Bạn có chắc chắn muốn hủy không?',
  MESSAGES_0019: 'Hủy thành công',
  MESSAGES_0020: 'Hủy thị thực thành công',
  MESSAGES_0021: 'Chưa có bản ghi nào được chọn',
  MESSAGES_0022: 'Phân công  thành công',
  MESSAGES_0023: 'Yêu cầu bổ sung thành công',
  MESSAGES_0024: 'In thành công',
  MESSAGES_0025: 'Chấp thuận cấp thị thực',
  MESSAGES_0026: 'Từ chối cấp thị thực',
  MESSAGES_0027: 'Đồng ý đề xuất',
  MESSAGES_0028: 'Từ chối đề xuất',
  MESSAGES_0029: 'Đề xuất thành công',
  MESSAGES_0030: 'Yêu cầu bổ sung',
  MESSAGES_0031: 'Bạn có chắc chắn muốn đề xuất?',
  MESSAGES_0032: 'Tải xuống thành công',
  MESSAGES_0033: 'Đang tiến hành xuất excel',
  MESSAGES_0034: 'Bạn có muốn thay đổi trạng thái bản ghi không?',
  MESSAGES_0035: 'Thay đổi trạng thái bản ghi thành công',
  MESSAGES_0036: 'Thông báo',
  MESSAGES_0037: 'Bạn cần nhập đầy đủ tiêu đề và nội dung ở các ngôn ngữ',
  MESSAGES_0038: 'Đã tiến hành đồng bộ file',
  MESSAGES_0039: 'Chưa chọn cán bộ xử lý hồ sơ',
  MESSAGES_0040: 'Ký số offline tối đa 500 bản ghi',
  MESSAGES_0041: 'Bạn có chắc chắn muốn xử lý hồ sơ?',
  MESSAGES_0042: 'Bạn có chắc chắn muốn huỷ xác nhận hồ sơ?',
  MESSAGES_0043: 'Bạn có chắc chắn muốn đồng bộ hồ sơ?',
  MESSAGES_0044: 'Đồng bộ hồ sơ thành công',
  MESSAGES_0045: 'Có lỗi xảy ra khi xử lý theo lô, vui lòng xử lý lại',
  MESSAGES_0046: 'Duyệt lại hồ sơ thành công',
  MESSAGES_0047: 'Chuyển ký hồ sơ thành công',
  MESSAGES_0048: 'Bạn có chắc chắn muốn duyệt lại?',
  MESSAGES_0049: 'Mật khẩu đã hết hạn, vui lòng thay đổi mật khẩu',
  MESSAGES_0050: 'Giá trị xin cấp thị thực khác với giá trị hồ sơ khai báo, bạn có muốn tiếp tục?',
  MESSAGES_0051: 'Thời gian xin cấp thị thực khác với thời gian hồ sơ khai báo, bạn có muốn tiếp tục?',
};
export const MESSAGE_DATA_APPROVAL = {
  DATA_APPROVAL_001: 'Bạn có chắc chắn muốn duyệt không?',
};

export const MESSAGE_ACCOUNT = {
  MESSAGE_CONFIRM_DELETE: 'Bạn có chắc chắn muốn xóa tài khoản không?',
  MESSAGE_CONFIRM_RESET_PASSWORD: 'Bạn có chắc chắn muốn cập nhập mật khẩu không?',
  MESSAGE_DELETE: 'Xóa tài khoản thành công',
  MESSAGE_RESET_PASSWORD: 'Cập nhập mật khẩu thành công',
  MESSAGE_ACCOUNT_EXISTED: 'Có một hoặc nhiều tài khoản trùng nhau. Vui lòng kiểm tra lại'
}
