const CMS_SERVICE = '/cms-service';
const AUTH_SERVICE = '/authorization-service';
const USER_SERVICE = '/user-service';
export default {
  report_service: {
    OVERVIEW: {
      url: CMS_SERVICE + '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: CMS_SERVICE + '/chart',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: USER_SERVICE + '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: USER_SERVICE + '/role',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/role',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/role',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/role',
      method: 'DELETE',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: USER_SERVICE + '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: USER_SERVICE + '/user-role',
      method: 'GET',
    },
  },
  group: {
    ALL: {
      url: CMS_SERVICE + '/group/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: CMS_SERVICE + '/group',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/group',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/group',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/group',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: USER_SERVICE + '/menu',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: USER_SERVICE + '/user',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/user',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: USER_SERVICE + '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: USER_SERVICE + '/user/get-user-info',
      method: 'GET',
    },
    GET_NOTIFICATIONS: {
      url: CMS_SERVICE + '/notify',
      method: 'GET',
    },
    MARK_READ_NOTIFICATION: {
      url: CMS_SERVICE + '/notify/seen',
      method: 'PATCH',
    },
    PATCH_UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PATCH',
    },
    GET_ALL_UNIT: {
      url: USER_SERVICE + '/dm-dvql/get-all',
      method: 'GET',
    },
    GET_CAPTCHA: {
      url: AUTH_SERVICE + '/captcha/generate',
      method: 'GET',
    },
    RESET_PASSWORD: {
      url: USER_SERVICE + '/user/reset-password',
      method: 'PATCH',
    },
  },
  client: {
    GET_ALL: {
      url: USER_SERVICE + '/clients',
      method: 'GET',
    },
  },
  auth: {
    LOGIN: {
      url: AUTH_SERVICE + '/oauth/token',
      method: 'POST',
    },
    REFRESH_TOKEN: {
      url: AUTH_SERVICE + '/oauth/refresh-token',
      method: 'POST',
    },
    LOGOUT: {
      url: AUTH_SERVICE + '/oauth/revoke',
      method: 'DELETE',
    },
    PATCH_UPDATE: {
      url: USER_SERVICE + '/user',
      method: 'PATCH',
    },
  },
  upload_service: {
    UPLOAD: {
      url: CMS_SERVICE + '/upload',
      method: 'POST',
    },
  },
  chart: {
    DOMAIN_IP: {
      url: CMS_SERVICE + '/chart/account-domain-ip',
      method: 'GET',
    },
    ORGANIZATION: {
      url: CMS_SERVICE + '/dash-board/most-ten-visa',
      method: 'GET',
    },
    STATUS: {
      url: CMS_SERVICE + '/dash-board/thong-ke-xu-ly',
      method: 'GET',
    },
    SUCCESS_STATUS: {
      url: CMS_SERVICE + '/dash-board/ti-le-hs-tt',
      method: 'GET',
    },
    SUMMARY: {
      url: CMS_SERVICE + '/dash-board/summary',
      method: 'GET',
    },
    MANAGE_DATA: {
      url: CMS_SERVICE + '/agency/get-chart',
      method: 'GET',
    },
    PROVIDE_DATA: {
      url: CMS_SERVICE + 'agency/get-chart-dataset',
      method: 'GET',
    },
    FIELD_DATA: {
      url: CMS_SERVICE + '/fields/chart-field-dataset',
      method: 'GET',
    },
    TOTAL_FILE: {
      url: CMS_SERVICE + '/file/total-files',
      method: 'GET',
    },
    TOTAL_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/total-files-upload-by-month',
      method: 'GET',
    },
    TOTAL_FILE_DOWNLOAD: {
      url: CMS_SERVICE + '/file/total-files-download-by-month',
      method: 'GET',
    },
    TOTAL_AGENCIES_UPLOADED: {
      url: CMS_SERVICE + '/file/total-agencies-uploaded',
      method: 'GET',
    },
    TOP_FILE_UPLOAD: {
      url: CMS_SERVICE + '/file/top-10',
      method: 'GET',
    },
    TOP_AGENCIES: {
      url: CMS_SERVICE + '/file/top-10-agencies',
      method: 'GET',
    },
    FILE_EXTENSION: {
      url: CMS_SERVICE + '/file/files-extension',
      method: 'GET',
    },
  },
  gate: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-ck',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-ck/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-ck',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-ck',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-ck/',
      method: 'DELETE',
    },
  },
  fileAssignment: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/phan-cong',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/ho-so/xu-ly/can-bo',
      method: 'GET',
    },
    ASSIGNMENT: {
      url: CMS_SERVICE + '/ho-so/phan-cong',
      method: 'PATCH',
    },
    TOTAL_HS_PC: {
      url: CMS_SERVICE + '/ho-so/tong-hs-chua-phan-cong',
      method: 'GET',
    },
  },
  tariff: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-lp',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-lp',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-lp',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-lp/',
      method: 'DELETE',
    },
    LIST_HO_SO: {
      url: CMS_SERVICE + '/duyet',
      method: 'GET',
    },
  },
  fileApproval: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/duyet',
      method: 'GET',
    },
    APPROVAL: {
      url: CMS_SERVICE + '/ho-so/duyet',
      method: 'PATCH',
    },
    GET_ALL_LANH_DAO: {
      url: CMS_SERVICE + '/dm-nguoiduyet/get-all',
      method: 'GET',
    }
  },
  profileType: {
    GET_ALL: {
      url: CMS_SERVICE + '/dm-lhs/get-all',
      method: 'GET',
    },
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-lhs',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-lhs',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-lhs',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-lhs/',
      method: 'DELETE',
    },
  },
  signProfile: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/ky',
      method: 'GET',
    },
    SIGN: {
      url: CMS_SERVICE + '/ho-so/ky',
      method: 'PATCH',
    },
    UPLOAD_SIGN_MPS: {
      url: CMS_SERVICE + '/upload-list-base64',
      method: 'POST',
    },
    REVIEW: {
      url: CMS_SERVICE + '/ho-so/duyet-lai',
      method: 'PATCH',
    },
    TRANSFER: {
      url: CMS_SERVICE + '/ho-so/chuyen-ky',
      method: 'PATCH',
    },
    LIST_OFFICER: {
      url: CMS_SERVICE + '/ho-so/can-bo-ky',
      method: 'GET',
    },
  },
  visaCancel: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/huy',
      method: 'GET',
    },
    CANCEL: {
      url: CMS_SERVICE + '/ho-so/huy',
      method: 'PATCH',
    },
  },
  profileNew: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly',
      method: 'GET',
    },
    PROPOSE: {
      url: CMS_SERVICE + '/ho-so/xu-ly/de-xuat',
      method: 'PATCH',
    },
    PROPOSE_PARCEL: {
      url: CMS_SERVICE + '/ho-so/xu-ly/de-xuat-theo-lo',
      method: 'PATCH',
    },
    PLEASE_ADVISE: {
      url: CMS_SERVICE + '/ho-so/xu-ly/de-xuat/xuat-pdf',
      method: 'PATCH',
    },
    GET_BY_ID: {
      url: CMS_SERVICE + '/ho-so/',
      method: 'GET',
    },
    PROCESS_EVISA: {
      url: '/ekyc/process-evisa',
      method: 'POST',
    },
    TO_BASE_64: {
      url: CMS_SERVICE + '/base-64',
      method: 'GET',
    },
    UNPROCESSED_FILE: {
      url: CMS_SERVICE + '/ho-so/xu-ly/tong-ho-so',
      method: 'GET',
    },
    HANDLE_DUPLICATE_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/xac-nhan-dt',
      method: 'PATCH',
    },
    HANDLE_VIOLATE_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/xac-nhan-bbvp',
      method: 'PATCH',
    },
    HANDLE_CANCELLATION_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/xac-nhan-gth',
      method: 'PATCH',
    },
    CANCEL_DUPLICATE_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/huy-xac-nhan-dt',
      method: 'PATCH',
    },
    CANCEL_VIOLATE_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/huy-xac-nhan-bbvp',
      method: 'PATCH',
    },
    CANCEL_CANCELLATION_OBJECT: {
      url: CMS_SERVICE + '/ho-so/xu-ly/huy-xac-nhan-gth',
      method: 'PATCH',
    },
    KIEM_TRA_XUAT_CANH: {
      url: CMS_SERVICE + '/ho-so/',
      method: 'GET',
    },
    TOTAL_PRIORITY: {
      url: CMS_SERVICE + '/ho-so/xu-ly/tong-ho-so-uu-tien',
      method: 'GET',
    }
  },
  proposedProfile: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/de-xuat',
      method: 'GET',
    },
  },
  followDirective: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/xu-ly-tct',
      method: 'GET',
    },
  },
  additionalReception: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/tnbs',
      method: 'GET',
    },
    RECEPTION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/tnbs',
      method: 'PATCH',
    },
  },
  additionalRequest: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/ycbs',
      method: 'GET',
    },
    ADDITIONAL: {
      url: CMS_SERVICE + '/ho-so/xu-ly/ycbs',
      method: 'PATCH',
    },
  },
  visaSymbol: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-kyhieutt',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-kyhieutt/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-kyhieutt',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-kyhieutt',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-kyhieutt/',
      method: 'DELETE',
    },
  },
  passportType: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-lhc',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-lhc/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-lhc',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-lhc',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-lhc/',
      method: 'DELETE',
    },
  },
  purpose: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-md',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-md/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-md',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-md',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-md/',
      method: 'DELETE',
    },
  },
  international: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-qt',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-qt/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-qt',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-qt',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-qt/',
      method: 'DELETE',
    },
  },
  province: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-tt',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-tt/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-tt',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-tt',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-tt/',
      method: 'DELETE',
    },
    GET_DETAIL: {
      url: CMS_SERVICE + '/dm-tt/',
      method: 'GET',
    }
  },
  district: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-qh',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-qh/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-qh',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-qh',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-qh/',
      method: 'DELETE',
    },
    GET_BY_TT: {
      url: CMS_SERVICE + '/dm-qh/tt?tinh=',
      method: 'GET',
    },
    GET_DETAIL: {
      url: CMS_SERVICE + '/dm-qh/',
      method: 'GET',
    }
  },
  wards: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-phuongxa',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-phuongxa/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-phuongxa',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-phuongxa',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-phuongxa/',
      method: 'DELETE',
    },
    GET_DETAIL: {
      url: CMS_SERVICE + '/dm-phuongxa/',
      method: 'GET',
    }
  },
  searchInfoProfile: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/tra-cuu',
      method: 'GET',
    },
    EXPORT_PDF: {
      url: CMS_SERVICE + '/ho-so/tra-cuu/xuat-pdf',
      method: 'GET',
    },
  },
  watchProfile: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ho-so/xu-ly/theo-doi',
      method: 'GET',
    },
    EXPORT_PDF: {
      url: CMS_SERVICE + '/ho-so/xu-ly/theo-doi/xuat-pdf',
      method: 'GET',
    },
  },
  archivedRecords: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/thi-thuc/da-cap',
      method: 'GET',
    },
    EXPORT_PDF: {
      url: CMS_SERVICE + '/thi-thuc/xuat-pdf',
      method: 'GET',
    },
  },
  configCriteria: {
    GET_ALL: {
      url: CMS_SERVICE + '/tieu-chi',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/tieu-chi',
      method: 'POST',
    },
    GET_ALL_CAN_BO: {
      url: CMS_SERVICE + '/tieu-chi/all-cb',
      method: 'GET',
    },
    GET_HS_THEO_NGAY: {
      url: CMS_SERVICE + '/tieu-chi/hsmoiday',
      method: 'GET',
    },
  },
  unit: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-ltc',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-ltc/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-ltc',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-ltc',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-ltc/',
      method: 'DELETE',
    },
  },
  profileDetail: {
    EXPORT_PDF: {
      url: CMS_SERVICE + '/ho-so/',
      method: 'GET',
    },
    EXPORT_OBJECT_PDF: {
      url: CMS_SERVICE + '/ho-so/',
      method: 'GET',
    },
    GET_ALL_VISA_HISTORY: {
      url: CMS_SERVICE + '/ho-so/xu-ly/ls-cap-tt',
      method: 'GET',
    },
  },
  exchangeRate: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-tigia',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-tigia/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-tigia',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-tigia',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-tigia/',
      method: 'DELETE',
    },
  },
  warning: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/cau-hinh-canh-bao',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/cau-hinh-canh-bao',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/cau-hinh-canh-bao',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/cau-hinh-canh-bao',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/cau-hinh-canh-bao/',
      method: 'DELETE',
    },
  },
  SECURITY: {
    GET: {
      url: CMS_SERVICE + '/cau-hinh-bao-mat',
      method: 'GET',
    },
    UPDATE: {
      url: CMS_SERVICE + '/cau-hinh-bao-mat',
      method: 'PUT',
    },
  },
  log: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ql-log',
      method: 'GET',
    },
    EXPORT_PDF: {
      url: CMS_SERVICE + '/ql-log/xuat-pdf',
      method: 'PUT',
    },
  },
  control: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/doi-soat',
      method: 'GET',
    },
    EXPORT_PDF: {
      url: CMS_SERVICE + '/doi-soat/xuat-pdf',
      method: 'PUT',
    },
    EXPORT_EXCEL: {
      url: CMS_SERVICE + '/doi-soat/xuat-excel',
      method: 'PUT',
    },
    GET_COUNT: {
      url: CMS_SERVICE + '/doi-soat/count',
      method: 'GET',
    },
  },
  unitManagement: {
    GET_BY_CONDITION: {
      url: USER_SERVICE + '/dm-dvql',
      method: 'GET',
    },
    GET_ALL: {
      url: USER_SERVICE + '/dm-dvql/get-all',
      method: 'GET',
    },
    CREATE: {
      url: USER_SERVICE + '/dm-dvql',
      method: 'POST',
    },
    UPDATE: {
      url: USER_SERVICE + '/dm-dvql',
      method: 'PUT',
    },
    DELETE: {
      url: USER_SERVICE + '/dm-dvql/',
      method: 'DELETE',
    },
  },
  applicationApproved: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dash-board/success-date',
      method: 'GET',
    },
  },
  countryGroup: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-nhom-qt',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-nhom-qt/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-nhom-qt',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-nhom-qt',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-nhom-qt/',
      method: 'DELETE',
    },
  },
  dashboard: {
    processingStatus: {
      GET_ALL: {
        url: CMS_SERVICE + '/dash-board/thong-ke-tinh-trang-xu-ly',
        method: 'GET',
      },
      EXPORT: {
        url: CMS_SERVICE + '/dash-board/export-tinh-trang-xl',
        method: 'GET',
      },
      GET_TOTAL_PROFILE: {
        url: CMS_SERVICE + '/dash-board/thong-ke-tinh-trang-xu-ly-sum',
        method: 'GET',
      },
      GET_ALL_CAN_BO: {
        url: CMS_SERVICE + '/dash-board/all-cb',
        method: 'GET',
      },
    },
    purposeStatistics: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/thong-ke-muc-dich',
        method: 'GET',
      },
      EXPORT: {
        url: CMS_SERVICE + '/dash-board/export-thong-ke-muc-dich',
        method: 'GET',
      },
    },
    visaRate: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/ti-le-cap-tt',
        method: 'GET',
      },
      EXPORT_EXCEL: {
        url: CMS_SERVICE + '/dash-board/export-ti-le-cap-tt',
        method: 'GET',
      },
    },
    visaPeriod: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/thoi-han-tt',
        method: 'GET',
      },
      EXPORT_EXCEL: {
        url: CMS_SERVICE + '/dash-board/export-thoi-han-tt',
        method: 'GET',
      },
    },
    visaValue: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/gia-tri-tt',
        method: 'GET',
      },
      EXPORT_EXCEL: {
        url: CMS_SERVICE + '/dash-board/export-gia-tri-tt',
        method: 'GET',
      },
    },
    declareViolations: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/khai-vi-pham',
        method: 'GET',
      },
      EXPORT_EXCEL: {
        url: CMS_SERVICE + '/dash-board/export-khai-vi-pham',
        method: 'GET',
      },
    },
    temporaryAdd: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/du-kien-tam-tru',
        method: 'GET',
      },
      EXPORT: {
        url: CMS_SERVICE + '/dash-board/export-du-kien-tam-tru',
        method: 'GET',
      },
    },
    email: {
      GET_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/thong-ke-theo-email',
        method: 'GET',
      },
      EXPORT: {
        url: CMS_SERVICE + '/dash-board/export-thong-ke-theo-email',
        method: 'GET',
      },
    },
    temporary_residence_address: {
      GET_ALL_BY_CONDITION: {
        url: CMS_SERVICE + '/dash-board/thong-ke-dia-chi-tam-tru',
        method: 'GET',
      },
      EXPORT: {
        url: CMS_SERVICE + '/dash-board/export-thong-ke-dia-chi-tam-tru',
        method: 'GET',
      },
    },
  },
  language: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ngon-ngu',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/ngon-ngu',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/ngon-ngu',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/ngon-ngu',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/ngon-ngu/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/ngon-ngu/hien-thi',
      method: 'PATCH',
    },
  },
  faq: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/cau-hoi',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/cau-hoi/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/cau-hoi',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/cau-hoi',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/cau-hoi/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/cau-hoi/hien-thi',
      method: 'PATCH',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/cau-hoi',
      method: 'GET',
    },
  },
  userManual: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/qlhd',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/qlhd/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/qlhd',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/qlhd',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/qlhd/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/qlhd/hien-thi',
      method: 'PATCH',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/qlhd',
      method: 'GET',
    },
  },
  popup: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ql-popup',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/ql-popup/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/ql-popup',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/ql-popup',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/ql-popup/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/ql-popup/hien-thi',
      method: 'PATCH',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/ql-popup',
      method: 'GET',
    },
  },
  email: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/ql-email',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/ql-email/get-all',
      method: 'GET',
    },
    UPDATE: {
      url: CMS_SERVICE + '/ql-email',
      method: 'PUT',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/ql-email/hien-thi',
      method: 'PATCH',
    },
  },
  contact: {
    GET_ALL: {
      url: CMS_SERVICE + '/ql-lien-he',
      method: 'GET',
    },
    RESPONSE: {
      url: CMS_SERVICE + '/ql-lien-he/gui-phan-hoi',
      method: 'PUT',
    },
    DRAFT: {
      url: CMS_SERVICE + '/ql-lien-he/luu-nhap',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/ql-lien-he/',
      method: 'DELETE',
    },
  },
  posts: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/bai-viet',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/bai-viet',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/bai-viet',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/bai-viet',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/bai-viet/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/bai-viet/hien-thi',
      method: 'PATCH',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/bai-viet',
      method: 'GET',
    },
  },
  noteDeclaration: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/luu-y-khai-bao',
      method: 'GET',
    },
    GET_ALL: {
      url: CMS_SERVICE + '/luu-y-khai-bao',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/luu-y-khai-bao',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/luu-y-khai-bao',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/luu-y-khai-bao/',
      method: 'DELETE',
    },
    CHANGE_STATUS: {
      url: CMS_SERVICE + '/luu-y-khai-bao/hien-thi',
      method: 'PATCH',
    },
    GET_BY_CODE: {
      url: CMS_SERVICE + '/luu-y-khai-bao',
      method: 'GET',
    },
  },
  management: {
    organization: {
      GET_ALL: {
        url: CMS_SERVICE + '/hstc',
        method: 'GET'
      },
      CREATE: {
        url: CMS_SERVICE + '/hstc',
        method: 'POST'
      },
      GET_BY_ID: {
        url: CMS_SERVICE + '/hstc/',
        method: 'GET'
      },
      UPDATE: {
        url: CMS_SERVICE + '/hstc',
        method: 'PUT'
      },
      DELETE: {
        url: CMS_SERVICE + '/hstc/',
        method: 'DELETE',
      },
      DISPLAY: {
        url: CMS_SERVICE + '/hstc/hien-thi',
        method: 'PATCH',
      },
      GET_ALL_SELECTION: {
        url: CMS_SERVICE + '/hstc/get-all',
        method: 'GET'
      }
    }
  },
  synchronousControl: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dong-bo-file',
      method: 'GET'
    },
    GET_TOTAL_COUNT: {
      url: CMS_SERVICE + '/dong-bo-file/count',
      method: 'GET'
    },
    GET_BY_FILE_NAME: {
      url: CMS_SERVICE + '/dong-bo-file/',
      method: 'GET'
    },
    EXPORT: {
      url: CMS_SERVICE + '/dong-bo-file/export',
      method: 'GET'
    },
    SYNCHRO: {
      url: CMS_SERVICE + '/dong-bo-file/sync/',
      method: 'GET'
    },
  },
  leader: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/dm-nguoiduyet',
      method: 'GET'
    },
    GET_ALL: {
      url: CMS_SERVICE + '/dm-nguoiduyet/get-all',
      method: 'GET',
    },
    CREATE: {
      url: CMS_SERVICE + '/dm-nguoiduyet',
      method: 'POST',
    },
    UPDATE: {
      url: CMS_SERVICE + '/dm-nguoiduyet',
      method: 'PUT',
    },
    DELETE: {
      url: CMS_SERVICE + '/dm-nguoiduyet/',
      method: 'DELETE',
    },
  },
  profile: {
    SYNC: {
      url: CMS_SERVICE + '/ho-so/sync-to-es',
      method: 'POST'
    }
  },
  searchProfilesSync: {
    GET_BY_CONDITION: {
      url: CMS_SERVICE + '/tra-cuu-dong-bo',
      method: 'GET'
    },
    SYNC: {
      url: CMS_SERVICE + '/tra-cuu-dong-bo/sync-to-htn',
      method: 'POST'
    },
  },
};
